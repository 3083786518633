import React, { useState, useRef } from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

import './sidebar.css'
import { useCallback } from 'react';
import { getStorage } from '../services/localStorageService';
import { useSelector } from 'react-redux';
import { leftMenus } from '../store/actions/setting.action';

const Sidebar = () => {
  const [isHovered, setHovered] = useState(false);
  const bottomRef = useRef();

    const ModulesPermissions = getStorage('ModulesPermissions', 'object')

    const language = useSelector((state) => state.settingReducer.language)

  const handleScroll = () => {
    if (bottomRef.current.scrollTop == 0) {
      bottomRef.current.scroll({
        top: bottomRef.current.offsetHeight,
        left: 0,
        behavior: "smooth",
      });
    } else {
      bottomRef.current.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

    return (
        <div className="as-body-sidebar" ref={bottomRef} style={{ overflow: 'hidden', height: '100%' }}>
            <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl fixed-left" id="sidenav-main">

                <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} className="collapse navbar-collapse sidebar-width" id="sidenav-collapse-main"  >
                    <Nav>
                        <ul className="navbar-nav">
                            {ModulesPermissions?.dashboard?.allowAllModules && <li className="nav-item">
                                <NavLink className="nav-link" activeClassName='active' to="dashboard">
                                    <img src={require('../assets/sidebar/Dashboard.svg').default} className="mb-1 mt-3 sidebar-icon" />
                                    <p className="nav-link-text ms-1 mn-c p-0">{language === "en" ? "Dashboard" : leftMenus["Dashboard"]}</p>
                                </NavLink>
                            </li>}
                            {ModulesPermissions?.live?.allowAllModules && <li className="nav-item">
                                <NavLink className="nav-link" activeClassName='active' to="/live">
                                    <img src={require('../assets/sidebar/Live.svg').default} className="mb-1 mt-3 sidebar-icon" />
                                    <p className="nav-link-text  mn-c">{language === "en" ? "Live" : leftMenus["Live"]}</p>
                                </NavLink>
                            </li>}
                            {ModulesPermissions?.trips?.allowAllModules && <li className="nav-item">
                                <NavLink className="nav-link" activeClassName='active' to="/trips">
                                    {/* <img src={require('../assets/img/left_icons/new/trips.svg').default} className="mb-1 mt-3" /> */}
                                    <img src={require('../assets/sidebar/Trips.svg').default} className="mb-1 mt-3 sidebar-icon" />
                                    <p className="nav-link-text  mn-c">{language === "en" ? "Trips" : leftMenus["Trips"]}</p>
                                </NavLink>
                            </li>}
                            {ModulesPermissions?.reports?.allowAllModules && <li className="nav-item">
                                <NavLink className="nav-link" activeClassName='active' to="/reports">
                                    <img src={require('../assets/sidebar/Reports.svg').default} className="mb-1 mt-3 sidebar-icon" />
                                    <p className="nav-link-text  mn-c">{language === "en" ? "Reports" : leftMenus["Reports"]}</p>
                                </NavLink>
                            </li>}
                            {ModulesPermissions?.fleetManager?.allowAllModules && <li className="nav-item">
                                <NavLink className="nav-link" activeClassName='active' to="/fleet-manager">
                                    <img src={require('../assets/sidebar/FleetManager.svg').default} className="mb-1 mt-3 sidebar-icon" />
                                    <p className="nav-link-text  mn-c">{language === "en" ? "Fleet Manager" : leftMenus["Fleet Manager"]}</p>
                                </NavLink>
                            </li>}
                            {ModulesPermissions?.expanseManager?.allowAllModules && <li className="nav-item">
                                <NavLink className="nav-link" activeClassName='active' to="/expense-manager">
                                    <img src={require('../assets/sidebar/expense manager icon.svg').default} className="mb-1 mt-3 sidebar-icon" />
                                    <p className="nav-link-text  mn-c">{language === "en" ? "Expense Manager" : leftMenus["Expense Manager"]}</p>
                                </NavLink>
                            </li>}
                            {ModulesPermissions?.attendance?.allowAllModules && <li className="nav-item">
                                <NavLink className="nav-link" activeClassName='active' to="/attendance">
                                    <img src={require('../assets/sidebar/attendance.png').default} className="mb-1 mt-3 sidebar-icon" />
                                    <p className="nav-link-text  mn-c">{language === "en" ? "Attendance" : leftMenus["Attendance"]}</p>
                                </NavLink>
                            </li>}
                            {ModulesPermissions?.tolls?.allowAllModules && <li className="nav-item">
                                <NavLink className="nav-link" activeClassName='active' to="/tolls">
                                    <img src={require('../assets/sidebar/Tolls.svg').default} className="mb-1 mt-3 sidebar-icon" />
                                    <p className="nav-link-text  mn-c">{language === "en" ? "Tolls" : leftMenus["Tolls"]}</p>
                                </NavLink>
                            </li>}
                            {ModulesPermissions?.zoneAndPoi?.allowAllModules && <li className="nav-item">
                                <NavLink className="nav-link" activeClassName='active' to="/zone-poi">
                                    <img src={require('../assets/sidebar/ZoneAndPOI.svg').default} className="mb-1 mt-3 sidebar-icon" />
                                    <p className="nav-link-text  mn-c">{language === "en" ? "Zone & POIs" : leftMenus["Zone & POIs"]}</p>
                                </NavLink>
                            </li>}
                            {ModulesPermissions?.taskManager?.allowAllModules && <li className="nav-item">
                                <NavLink className="nav-link" activeClassName='active' to="/task_manager">
                                    <img src={require('../assets/sidebar/TaskManager.svg').default} className="mb-1 mt-3 sidebar-icon" />
                                    <p className="nav-link-text  mn-c">{language === "en" ? "Task Manager" : leftMenus["Task Manager"]}</p>
                                </NavLink>
                            </li>}
                            {ModulesPermissions?.assetsTracking?.allowAllModules && <li className="nav-item">
                                <NavLink className="nav-link" activeClassName='active' to="/assets-manager">
                                    <img src={require('../assets/sidebar/Rental.svg').default} className="mb-1 mt-3 sidebar-icon" />
                                    <p className="nav-link-text  mn-c">{language === "en" ? "Assets Tracking" : leftMenus["Assets Tracking"]}</p>
                                </NavLink>
                            </li>}
                            {ModulesPermissions?.immobilizer?.allowAllModules && <li className="nav-item">
                                <NavLink className="nav-link" activeClassName='active' to="/immobilizer">
                                    <img src={require('../assets/sidebar/Immobilizer.svg').default} className="mb-1 mt-3 immobilizer-icon " />
                                    <p className="nav-link-text  mn-c">{language === "en" ? "Immobilizer" : leftMenus["Immobilizer"]}</p>
                                </NavLink>
                            </li>}
                            {ModulesPermissions?.dashCam?.allowAllModules && <li className="nav-item">
                                <NavLink className="nav-link" activeClassName='active' to="/cam">
                                    <img src={require('../assets/sidebar/cam.svg').default} className="mb-1 mt-3 immobilizer-icon " />
                                    <p className="nav-link-text  mn-c">{language === "en" ? "Cam" : leftMenus["Cam"]}</p>
                                </NavLink>
                            </li>}
                            {/* <li className="nav-item">
                        <NavLink className="nav-link" activeClassName='active' to="/rental">
                            <img src={require('../assets/img/left_icons/new/rental.svg').default} className="mb-1 mt-3" />
                            <p className="nav-link-text  mn-c">Rental</p>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" activeClassName='active' to="/vehicle-inspection">
                            <img src={require('../assets/img/left_icons/new/delivery.svg').default} className="mb-1 mt-3" />
                            <p className="nav-link-text">Vehicle Inspection</p>
                        </NavLink>
                    </li> */}
            </ul>

            {isHovered && (
              <div className="downward">
                {bottomRef.current.scrollTop == 0 ? (
                  <FaChevronDown
                    color="#fff"
                    size={14}
                    onClick={handleScroll}
                  />
                ) : (
                  <FaChevronUp color="#fff" size={14} onClick={handleScroll} />
                )}
              </div>
            )}
          </Nav>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
