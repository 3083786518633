import { API_URLS } from "../../configs/api.urls";
import GetRequest from "../../services/GetRequest";
import { getStorage } from "../../services/localStorageService";
import PostRequest from "../../services/PostRequest";
import TaskManagerRequest from "../../services/TaskManagerRequest";

export const GET_SINGLE_OBJECT_DETAILS = 'GET_SINGLE_OBJECT_DETAILS'
export const SET_ACTIVE_VEHICLE = 'SET_ACTIVE_VEHICLE'
// Service Reminder
export const GET_SERVICE_REMINDER = 'GET_SERVICE_REMINDER'
export const ADD_SERVICE_REMINDER = 'ADD_SERVICE_REMINDER'
export const GET_SERVICE_REMINDER_HISTORY = 'GET_SERVICE_REMINDER_HISTORY'
export const DELETE_SERVICE_REMINDER = 'DELETE_SERVICE_REMINDER'
export const GET_SINGLE_REMINDER = 'GET_SINGLE_REMINDER'
export const EDIT_SERVICE_REMINDER = 'EDIT_SERVICE_REMINDER'
export const GET_DEFAULT_REMINDER_SERVICE = 'GET_DEFAULT_REMINDER_SERVICE'
// Documenr Reminder
export const GET_DOCUMENT_REMINDER = 'GET_DOCUMENT_REMINDER'
export const GET_DOCUMENT_REMINDER_HISTORY = 'GET_DOCUMENT_REMINDER_HISTORY'
export const DELETE_DOCUMENT_REMINDER = 'DELETE_DOCUMENT_REMINDER'
export const GET_SINGLE_DOCUMENT = 'GET_SINGLE_DOCUMENT'
export const Edit_DOCUMENT_REMINDER = 'Edit_DOCUMENT_REMINDER'
export const GET_REMINDER_EMAIL = 'GET_REMINDER_EMAIL'
export const ADD_REMINDER_EMAILS = 'ADD_REMINDER_EMAILS'
// Fleet Service
export const GET_SERVICE_LIST = 'GET_SERVICE_LIST'
export const GET_SERVICE = 'GET_SERVICE'
export const EDIT_SERVICE = 'EDIT_SERVICE'
export const DELETE_SERVICE =  'DELETE_SERVICE'
export const UPLOAD_SERVICE_IMAGE = 'UPLOAD_SERVICE_IMAGE'
export const GET_DEFAULT_SERVICES = 'GET_DEFAULT_SERVICES'
//fuel
export const GET_FUEL_LIST = 'GET_FUEL_LIST'
export const GET_DRIVERS = 'GET_DRIVERS'
export const GET_FUEL = 'GET_FUEL'
export const EDIT_FUEL = 'EDIT_FUEL'
export const DELETE_FUEL = 'DELETE_FUEL'
export const UPLOAD_FUEL_IMAGE = 'UPLOAD_FUEL_IMAGE'
export const IMPORT_FUEL = 'IMPORT_FUEL'

// Other expenses 
export const GET_OTHER_EXPENSE_LIST = "GET_OTHER_EXPENSE_LIST";
export const GET_OTHER_EXPENSE = "GET_OTHER_EXPENSE";
export const EDIT_OTHER_EXPENSE = "EDIT_OTHER_EXPENSE";
export const DELETE_OTHER_EXPENSE = "DELETE_OTHER_EXPENSE";
export const UPLOAD_OTHER_EXPENSE_IMAGE = "UPLOAD_OTHER_EXPENSE_IMAGE";


export const getSingleObjectDetails = (id = '') => {

    return async dispatch => {

        const response = await GetRequest('/user/getSingleObjectDetails/' + id, {})

        dispatch({
            type: GET_SINGLE_OBJECT_DETAILS,
            value: response[0]
        })
    };


}

export const setActiveVehicle = (id = '') => {

    return dispatch => {

        dispatch({
            type: SET_ACTIVE_VEHICLE,
            value: id
        })

    };

}

// Service Reminder

export const getServiceRemainder = (id = '', page = 1) => {

    return async dispatch => {

        const response = await GetRequest(`/fleet/getServiceRemainder/${id}?page=${page}`, {}, API_URLS.API_GET_URL_PLUS)

        dispatch({
            type: GET_SERVICE_REMINDER,
            value: response
        })
    };

}

export const getDefaultReminderServices = () => {

    return async dispatch => {

        const response = await GetRequest(`/fleet/getDefaultRemServices`, {})

        dispatch({
            type: GET_DEFAULT_REMINDER_SERVICE,
            value: response
        })
    };

}

export const getDefaultServices = () => {

    return async dispatch => {

        const response = await GetRequest(`/fleet/getDefaultServices`, {})

        dispatch({
            type: GET_DEFAULT_SERVICES,
            value: response
        })
    };

}

export const getServiceRemainderHistory = (id = '') => {

    return async dispatch => {

        const response = await GetRequest(`/fleet/getAllReminderHistory/${id}`, {})

        dispatch({
            type: GET_SERVICE_REMINDER_HISTORY,
            value: response.data
        })
    };

}

export const addNewRemainder = (data) => {

    const token = getStorage('__token__');

    data['X-CSRF-TOKEN'] = token

    return async dispatch => {

        const response = await PostRequest(`/fleet/addNewRemainder/${data.device_id}`, data)

        dispatch({
            type: ADD_SERVICE_REMINDER,
            value: response
        })
    };

}

export const getSingleRemainder = (id,device_id) => {

    return async dispatch => {

        const response = await GetRequest(`/fleet/getSingleRemainder/${id}/${device_id}`, {})

        dispatch({
            type: GET_SINGLE_REMINDER,
            value: response.data[0]
        })
    };

}

export const editServiceReminder = (data) => {

    return async dispatch => {

        const response = await PostRequest(`/fleet/editRemainder/${data.remainder_id}`, data)

        dispatch({
            type: EDIT_SERVICE_REMINDER,
            value: ''
        })
    };

}

export const deleteServiceReminder = (id) => {

    return async dispatch => {

        const response = await GetRequest(`/fleet/deleteRemainder/${id}`, {})

        dispatch({
            type: DELETE_SERVICE_REMINDER,
            value: ''
        })
    };

}

// Document Reminder

export const getDocumentRemainder = (id = '', page = 1) => {

    return async dispatch => {

        const response = await GetRequest(`/fleet/getDocumentRemainder/${id}?page=${page}`, {})

        dispatch({
            type: GET_DOCUMENT_REMINDER,
            value: response
        })
    };

}

export const getDocumentRemainderHistory = (id = '') => {

    return async dispatch => {

        const response = await GetRequest(`/fleet/getAllDocumentHistory/${id}`, {})

        dispatch({
            type: GET_DOCUMENT_REMINDER_HISTORY,
            value: response.data
        })
    };

}


export const addNewDocument = (data) => {

    return async dispatch => {

        const response = await PostRequest(`/fleet/addNewDocument/${data.device_id}`, data)
        return response;
       
    };

}

export const getSingleDocument = (id) => {

    return async dispatch => {

        const response = await GetRequest(`/fleet/getSingleDocument/${id}`, {})

        dispatch({
            type: GET_SINGLE_DOCUMENT,
            value: response.data
        })
    };

}

export const editDocument = (data) => {
    
    return async dispatch => {
        
        const response = await PostRequest(`/fleet/editDocument/${data.document_id}`, data)

        dispatch({
            type: Edit_DOCUMENT_REMINDER,
            value: ''
        })
    };

}

export const uploadDocumentImage = (id, data) => {

    return async dispatch => {

        const response = await PostRequest(`/fleet/uploadDocumentImage/${id}`, data)
        return response

    };

}

export const deleteDocumentReminder = (id) => {

    return async dispatch => {

        const response = await GetRequest(`/fleet/deleteDocument/${id}`, {})

        dispatch({
            type: DELETE_DOCUMENT_REMINDER,
            value: ''
        })
    };

}

export const getReminderEmail = () => {

    return async dispatch => {

        const user = getStorage('__user__', 'object')
        const id = user && user.organisation ? user.organisation.id : null

        const serviceResponse = await GetRequest(`/fleet/getReminderEmail/service/${id}`, {})
        const documentResponse = await GetRequest(`/fleet/getReminderEmail/document/${id}`, {})

        dispatch({
            type: GET_REMINDER_EMAIL,
            serviceReminders: serviceResponse[0],
            documentReminders: documentResponse[0],
        })
    };

}

export const addNewReminderEmails = (data) => {

    return async dispatch => {

        const response = await PostRequest(`/fleet/addNewReminderEmails/${data.reminder_emails_type}/${data.reminder_emails_org_id}`, data)

        dispatch({
            type: ADD_REMINDER_EMAILS,
            value: ''
        })
    };

}

// fleet service

export const getServiceList = (id = '', page = 1) => {

    return async dispatch => {

        const response = await GetRequest(`/fleet/getServiceList/${id}?page=${page}`, {})

        dispatch({
            type: GET_SERVICE_LIST,
            value: response
        })
    };

}

export const getService = (id = '') => {

    return async dispatch => {

        const response = await GetRequest(`/fleet/getService/${id}`, {})

        dispatch({
            type: GET_SERVICE,
            value: response.data[0]
        })
    };

}

export const addService = (data) => {
    return async dispatch => {

        const response = await PostRequest(`/fleet/addNewService/${data.device_id}`, data, 'service-submit-btn');
        return response;
        
    };
}


export const editService = (data) => {

    return async dispatch => {

        const response = await PostRequest(`/fleet/editService/${data.service_id}`, data, 'service-submit-btn');

        dispatch({
            type: EDIT_SERVICE,
            value: response
        });

        return response;
    };

}


export const deleteService = (id = '') => {

    return async dispatch => {

        const response = await GetRequest(`/fleet/deleteService/${id}`, {})

        dispatch({
            type: DELETE_SERVICE,
            value: response
        })
    };

}

export const uploadServiceImage = (id = '', data) => {

    return async dispatch => {

        const response = await PostRequest(`/fleet/uploadServiceImage/${id}`, data, 'service-submit-btn')

        dispatch({
            type: UPLOAD_SERVICE_IMAGE,
            value: response
        })
    };

}

// Fuel 

export const getFuelList = (id = '', page = 1) => {

    return async dispatch => {

        const response = await GetRequest(`/fleet/getFuelList/${id}?page=${page}`, {})

        dispatch({
            type: GET_FUEL_LIST,
            value: response
        })
    };

}

export const getFuel = (id = '') => {

    return async dispatch => {

        const response = await GetRequest(`/fleet/getSingleFuel/${id}`, {})

        dispatch({
            type: GET_FUEL,
            value: response.data[0]
        })
    };

}


export const addFuel = (data) => {
    return async dispatch => {

        const response = await PostRequest(`/fleet/addNewFuel/${data.device_id}`, data, 'fuel-submit-btn')
        return response;
        
    };
}

export const editFuel = (data) => {

    return async dispatch => {

        const response = await PostRequest(`/fleet/editFuel/${data.fuel_id}`, data)

        dispatch({
            type: EDIT_FUEL,
            value: response
        });

        return response;
    };

}


export const deleteFuel = (id = '') => {

    return async dispatch => {

        const response = await GetRequest(`/fleet/deleteFuel/${id}`, {})

        dispatch({
            type: DELETE_FUEL,
            value: response
        })
    };

}

export const uploadFuelImage = (id = '', data) => {

    return async dispatch => {

        const response = await PostRequest(`/fleet/uploadFuelImage/${id}`, data, 'fuel-submit-btn')

        dispatch({
            type: UPLOAD_FUEL_IMAGE,
            value: response
        })
    };

}

export const getDrivers = () => {

    return async dispatch => {

        const id = getStorage('__user__', 'object') ? getStorage('__user__', 'object').id : null

        const response = await GetRequest(`/user/getDriversUnderOrganisation/${id}`, {})

        console.log(response.drivers)
        dispatch({
            type: GET_DRIVERS,
            value: response.drivers
        })
    };

}

export const ImportServices = (data) => {

    return async dispatch => {
        const response = await TaskManagerRequest(`/fleet-manager/services/import`, data, true, 'submit-btn');
        return response;
    };
}

export const ImportFuel = (data) => {
    return async dispatch => {
        const response = await TaskManagerRequest(`/fleet-manager/fuel/import`, data, true, 'submit-btn');
        return response;
    }
}

// other expenses 
export const getOtherExpenseList = (id = "", page = 1) => {
    return async dispatch => {

        const response = await GetRequest(`/fleet/getOtherExpenseList/${id}?page=${page}`, {})

        dispatch({
            type: GET_OTHER_EXPENSE_LIST,
            value: response
        })
    };
}

export const addOtherExpense = (data) => {
    return async dispatch => {

        const response = await PostRequest(`/fleet/saveOtherExpense/${0}`, data, 'other-submit-btn')
        return response;
        
    };
}

export const editOtherExpense = (id, data) => {

    return async dispatch => {

        const response = await PostRequest(`/fleet/saveOtherExpense/${id}`, data, "other-submit-btn")
        return response;
    };

}


export const deleteOtherExpense = (id = '') => {

    return async dispatch => {

        const response = await GetRequest(`/fleet/deleteOtherExpense/${id}`, {})

       return response;
    };

}