import GetRequest from "../../services/GetRequest";
import { getStorage, setStorage } from "../../services/localStorageService";
import PostRequest from "../../services/PostRequest";
import TaskManagerRequest from "../../services/TaskManagerRequest";
import _ from "underscore";

export const SET_GROUPS = "SET_GROUPS";
export const SORT_GROUPS = "SORT_GROUPS";
export const SET_POSITIONS = "SET_POSITIONS";
export const UPDATE_POSITION = "UPDATE_POSITION";
export const GET_MARKER = "GET_MARKER";
export const FILTER_VEHICLES = "FILTER_VEHICLES";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const TOGGLE_IN_MAP = "TOGGLE_IN_MAP";
export const CHANGE_MAP_CENTER_ZOOM = "CHANGE_MAP_CENTER_ZOOM";
export const SEARCH_VEHICLE = "SEARCH_VEHICLE";
export const REFRESH_GROUPS = "REFRESH_GROUPS";
export const MAP_LOADED = "MAP_LOADED";
export const CURRUNTLY_DISPLAYED_VEHICLES = "CURRUNTLY_DISPLAYED_VEHICLES";
export const TOGGLE_GROUP_MARKER = "TOGGLE_GROUP_MARKER";
export const GET_DRIVERS = "GET_DRIVERS";
export const GET_DRIVER_GROUPS = "GET_DRIVER_GROUPS";
export const GET_DRIVER_INFO = "GET_DRIVER_INFO";
export const IS_SOCKET_CONNECTED = "IS_SOCKET_CONNECTED";

export const GET_POI = "GET_POI";
export const GET_ZONES = "GET_ZONES";
export const GET_ALERTS = "GET_ALERTS";
export const SET_ACTIVE_ALERT = "SET_ACTIVE_ALERT";
export const GET_ALL_SHARED_LOCATIONS = "GET_ALL_SHARED_LOCATIONS";
export const GET_SINGLE_OBJECT_DETAILS = "GET_SINGLE_OBJECT_DETAILS";
export const GET_CURRENT_TRACK = "GET_CURRENT_TRACK";

export const SELECT_ALL_GROUPS = "SELECT_ALL_GROUPS";
export const GET_VEHICLE_DRIVERS = "GET_VEHICLE_DRIVERS";
export const SET_DRIVERS_LOADING = "SET_DRIVERS_LOADING";
// export const GET_ON_POSITIONS = 'GET_ON_POSITIONS'

export const setGroups = () => {
  const groups = getStorage("__groups__", "object");
  var isSorted = getStorage("sortedGroup");

  // var groups_sorted = JSON.parse(JSON.stringify(groups)) || [];
  // groups_sorted = sortGroups(groups_sorted)
  // setStorage("sortedGroup", groups_sorted, "object");

  return (dispatch) => {
    dispatch({
      type: SET_GROUPS,
      value: groups,
      sortBy: isSorted != "true" ? "name" : "id",
    });
  };
};

export const sortGroups = () => {
  var isSorted = getStorage("sortedGroup");
  if (isSorted != "true") {
    isSorted = false;
  } else {
    isSorted = true;
  }

  setStorage("sortedGroup", !isSorted);

  return (dispatch) => {
    dispatch({
      type: SORT_GROUPS,
      value: !isSorted ? "name" : "id",
    });
  };
};

export const selectAllGroups = (isSelected) => {
  return (dispatch) => {
    dispatch({
      type: SELECT_ALL_GROUPS,
      value: isSelected,
    });
  };
};

export const setSocketConnection = (status = true) => {
  return (dispatch) => {
    dispatch({
      type: IS_SOCKET_CONNECTED,
      value: status,
    });
  };
};

export const toggleSidebar = () => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_SIDEBAR,
      value: "",
    });
  };
};

export const getDrivers = () => {
  try {
    return async (dispatch) => {
      let response = await GetRequest("/user/getDrivers");

      dispatch({
        type: GET_DRIVERS,
        value: response.data,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

// export const getOnPositions = () => {

//     try {
//         return async dispatch => {

//             let response = await GetRequest('/user/getOnPosition');
//             console.log("getOnPosition........", response);

//             dispatch({
//                 type: GET_ON_POSITIONS,
//                 value: response
//             })

//         };

//     } catch (error) {
//         console.log('Error : ', error)
//         return false;
//     }

// }

export const getVehicleDrivers = () => {
  try {
    return async (dispatch) => {
      var response = await TaskManagerRequest("/get/drivers");

      var data = {};

      response.map((driver) => {
        if (driver.vehicle && driver.vehicle != "")
          data[driver.vehicle] = driver;
      });

      dispatch({
        type: GET_VEHICLE_DRIVERS,
        value: data,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getDriverInfo = (data) => {
  return new Promise(async (resolve) => {
    try {
      let response = await PostRequest("/user/getdriverinfo", data);
      // let response = {
      //     driver_id: 3301,
      //     driver_name: "John Dan",
      //     driver_phone: "+971 50 874 66 88",
      //     driver_image: "",
      //     driver_description: "John",
      //     driver_status: "",
      //     i_button_driver_id: "008171",
      //     obj_name: "",
      //     GZ: "Not in Geozone",
      //     totalDistance: 70433658.11,
      //     machinehours: 0,
      // }

      resolve(response);
    } catch (error) {
      resolve(false);
    }
  });
};

export const getDriverGroups = () => {
  try {
    return async (dispatch) => {
      const user = getStorage("__user__", "object");

      dispatch({
        type: SET_DRIVERS_LOADING,
        value: true,
      });

      // let response = await GetRequest('/user/getDriverGroups');
      const response = await TaskManagerRequest(`/get/driver/groups`, {});
      // response.push({ id: -1, name: user.name })

      dispatch({
        type: GET_DRIVER_GROUPS,
        value: response,
      });

      dispatch({
        type: SET_DRIVERS_LOADING,
        value: false,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const setPositions = (positions, init) => {
  return (dispatch) => {
    if (init) {
      dispatch({
        type: SET_POSITIONS,
        value: positions,
      });
    } else {
      // Wait for load Map
      // setTimeout(() => {
      // var p = positions.slice(0,5);

      dispatch({
        type: UPDATE_POSITION,
        value: positions,
      });
      // },5000)
    }
  };
};

export const getMarker = (deviceId) => {
  return async (dispatch) => {
    dispatch({
      type: GET_MARKER,
      value: deviceId,
    });
  };
};

export const filterVehicles = (type) => {
  return async (dispatch) => {
    dispatch({
      type: FILTER_VEHICLES,
      value: type,
    });
  };
};

export const toggleShowInMap = (vehicle) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_IN_MAP,
      value: vehicle,
    });
  };
};

export const changeMapPositioon = (vehicleID) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_MAP_CENTER_ZOOM,
      value: vehicleID,
    });
  };
};

export const searchVehicles = (center) => {
  return (dispatch) => {
    dispatch({
      type: SEARCH_VEHICLE,
      value: center,
    });
  };
};

export const refreshGroups = () => {
  return (dispatch) => {
    dispatch({
      type: REFRESH_GROUPS,
    });
  };
};

export const setMapLoaded = () => {
  return (dispatch) => {
    dispatch({
      type: MAP_LOADED,
    });
  };
};

export const changeCurruntlyDisplayedVehicles = (vehicles) => {
  return (dispatch) => {
    dispatch({
      value: vehicles,
      type: CURRUNTLY_DISPLAYED_VEHICLES,
    });
  };
};

export const toggleGroupMapMarker = (groupID) => {
  return (dispatch) => {
    dispatch({
      value: groupID,
      type: TOGGLE_GROUP_MARKER,
    });
  };
};

export const getPOI = () => {
  try {
    return async (dispatch) => {
      let response = await GetRequest("/geozones/getPOIs");

      var points = [];

      if (response.geozonesGroupsForUser && response.geozonesGroupsForUser[0]) {
        points = response.geozonesGroupsForUser[0].points;
      }

      dispatch({
        type: GET_POI,
        value: points,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const clearPOI = () => {
  try {
    return async (dispatch) => {
      dispatch({
        type: GET_POI,
        value: [],
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getGeoZones = () => {
  return async (dispatch) => {
    try {
      const response = await GetRequest(
        "/geozones/getGeozoneGroupsForUser",
        {}
      );

      var zones = [];
      var zoneIds = [];

      if (response.geozonesGroupsForUser) {
        response.geozonesGroupsForUser.map((g) => {
          zones = [...zones, ...g.geozone];
        });

        zones.map((g) => {
          zoneIds.push(g.gz_id);
        });
      }

      const response1 = await PostRequest(
        `/geozones/getGroupGeozones`,
        zoneIds
      );

      dispatch({
        type: GET_ZONES,
        value: response1,
      });

      return true;
    } catch (error) {
      return false;
    }
  };
};

export const getAlerts = (from, to, data) => {
  try {
    return async (dispatch) => {
      let response = await PostRequest(
        `/event/getAllEvents/${from}/${to}`,
        data
      );

      dispatch({
        type: GET_ALERTS,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const setActiveAlert = (alert) => {
  return async (dispatch) => {
    dispatch({
      type: SET_ACTIVE_ALERT,
      value: alert,
    });
  };
};

export const getAllSharedLocations = (id) => {
  return async (dispatch) => {
    const response = await GetRequest(`/user/getAllShareLocations/${id}`, {});

    dispatch({
      type: GET_ALL_SHARED_LOCATIONS,
      value: response.getAllShareLocation,
    });
  };
};

export const deleteSharedLocations = (id) => {
  return async (dispatch) => {
    const response = await GetRequest(`/user/deleteShareLocation/${id}`, {});
    return response;
  };
};

export const addSharedLocations = (data) => {
  return async (dispatch) => {
    const response = await PostRequest(`/user/AddNewShareLocation`, data);
    return response;
  };
};

///user/GetCurrentTrack/357454074544436/2758
export const GetCurrentTrack = (id, uniqueId) => {
  return async (dispatch) => {
    const response = await GetRequest(
      `/user/GetCurrentTrack/${uniqueId}/${id}`,
      {}
    );

    dispatch({
      type: GET_CURRENT_TRACK,
      value: response,
    });
  };
};

export const getSingleObjectDetails = (id) => {
  return async (dispatch) => {
    const response = await GetRequest(`/user/getSingleObjectDetails/${id}`, {});

    dispatch({
      type: GET_SINGLE_OBJECT_DETAILS,
      value: response[0],
    });
  };
};

export const setSingleObjectDetails = (data) => {
  return async (dispatch) => {
    dispatch({
      type: GET_SINGLE_OBJECT_DETAILS,
      value: data,
    });
  };
};

export const editObject = (data) => {
  return async (dispatch) => {
    const response = await PostRequest(`/user/editObject/${data.obj_id}`, data);
    return response;
  };
};
