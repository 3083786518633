import {
  SET_COMMUNITY_DRIVERS,
  SET_COMMUNITY_GROUPS,
  SET_COMMUNITY_DRIVER_GROUPS,
  SET_ACTIVE_COMMUNITY_DRIVER,
  GET_COMMUNITY_TRIPS_SUMMARY,
  SET_COMMUNITY_LOADING,
} from "../actions/community.action";
import { GET_COMMUNITY_SETTINGS } from "../actions/setting.action";

const initialState = {
  drivers: [],
  groups: [],
  driverGroups: [],
  communitySettings: {},
  activeDriver: {},
  tripssummary : {},
  isLoading : false,
};

const CommunityReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_COMMUNITY_LOADING : 
      return {...state, isLoading : !state.isLoading};

    case SET_COMMUNITY_DRIVERS:
      return { ...state, drivers: action.value };

    case SET_COMMUNITY_GROUPS:
      return { ...state, groups: action.value };

    case SET_COMMUNITY_DRIVER_GROUPS:
      return { ...state, driverGroups: action.value };

    case GET_COMMUNITY_SETTINGS:
      return { ...state, communitySettings: action.value };

    case SET_ACTIVE_COMMUNITY_DRIVER: 
      return { ...state, activeDriver: action.value };
    

    case GET_COMMUNITY_TRIPS_SUMMARY:
      return {
        ...state,
        tripssummary: {
          ...state.tripssummary,
          ...action.value,
          today: {
            ...state.tripssummary?.today,
            ...action.value?.today,
          },
          month: {
            ...state.tripssummary?.month,
            ...action.value?.month
          }
        },
      };
    
    

    default:
      return state;
  }
};

export default CommunityReducer;
