import {

    GET_SINGLE_OBJECT_DETAILS, SET_ACTIVE_VEHICLE,
    GET_SERVICE_REMINDER, GET_SERVICE_REMINDER_HISTORY,
    GET_SINGLE_REMINDER, GET_REMINDER_EMAIL,
    GET_DOCUMENT_REMINDER, GET_DOCUMENT_REMINDER_HISTORY,
    GET_SINGLE_DOCUMENT,
    GET_SERVICE_LIST, GET_SERVICE,
    GET_FUEL_LIST, GET_DRIVERS, GET_FUEL,
    GET_DEFAULT_REMINDER_SERVICE,
    GET_DEFAULT_SERVICES,
    GET_OTHER_EXPENSE_LIST,
    GET_OTHER_EXPENSE

} from "../actions/fleetManager.action";

const initialState = {
    objectDetails: {},
    activeVehicle: null,
    serviceReminder: {},
    activeServiceReminder : {},
    activeDocumentReminder : {},
    activeService : {},
    activeFuel: {},
    serviceReminderHistory: [],
    documentReminder: {},
    documentReminderHistory: [],
    serviceList: [],
    fuelList: [],
    drivers: [],
    serviceReminderEmails : {},
    documentReminderEmails : {},
    defaultServices : [],
    defaultReminderServices : [],
    defaultServices : [],
    otherExpenseList : [],
    activeOtherExpense : {},
}

const FleetManagerReducer = (state = initialState, action) => {

    switch (action.type) {

        case GET_SINGLE_OBJECT_DETAILS:
            return { ...state, objectDetails: action.value }

        case SET_ACTIVE_VEHICLE:
            return { ...state, activeVehicle: action.value }

        case GET_SERVICE_REMINDER:
            return { ...state, serviceReminder: action.value }

        case GET_SERVICE_REMINDER_HISTORY:
            return { ...state, serviceReminderHistory: action.value }
        
        case GET_SINGLE_REMINDER:
            return { ...state, activeServiceReminder: action.value }

        case GET_DEFAULT_REMINDER_SERVICE:
            return { ...state, defaultReminderServices: action.value }
        
        case GET_REMINDER_EMAIL:
            return { ...state, serviceReminderEmails: action.serviceReminders, documentReminderEmails: action.documentReminders}

        case GET_DOCUMENT_REMINDER:
            return { ...state, documentReminder: action.value }

        case GET_DOCUMENT_REMINDER_HISTORY:
            return { ...state, documentReminderHistory: action.value }

        case GET_SINGLE_DOCUMENT:
            return { ...state, activeDocumentReminder: action.value }

        // service
        case GET_SERVICE_LIST:
            return { ...state, serviceList: action.value }

        case GET_SERVICE:
            return { ...state, activeService: action.value }

        case GET_DEFAULT_SERVICES:
            return { ...state, defaultServices: action.value }

        //fuel

        case GET_FUEL : 
        return { ...state, activeFuel: action.value }

        case GET_FUEL_LIST:
            return { ...state, fuelList: action.value }

        case GET_DRIVERS:
            return { ...state, drivers: action.value }

        case GET_OTHER_EXPENSE_LIST:
            return {...state, otherExpenseList : action.value}

        case GET_OTHER_EXPENSE:
            return {...state, activeOtherExpense : action.value}

        default:
            return state

    }

}

export default FleetManagerReducer;